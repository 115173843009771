const mobileMenu = () => {
  const elements = {
    navIcons: document.querySelectorAll(".js-nav-button"),
    navMobile: document.querySelector(".header-nav-box"),
  };

  const toggleMenu = (event) => {
    document.body.classList.toggle("overflow");
    elements.navMobile.classList.toggle("header-nav-box--visible");
    event.currentTarget.classList.toggle("burger--active");
  };

  elements.navIcons.forEach(navIcon => {
    navIcon.addEventListener("click", toggleMenu);
  });
};

export default mobileMenu;
