import { BREAKPOINTS } from "../constants";

const animationTrigger = () => {
  const observerForIntro = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const intersectionRect = entry.intersectionRect;
      const viewportHeight = window.innerHeight;
      const objectCenterY = intersectionRect.top + intersectionRect.height / 2;
      const isInViewportCenter = objectCenterY >= 0 && objectCenterY <= viewportHeight;

      if (entry.isIntersecting && isInViewportCenter) {
        entry.target.classList.add('section-start');
      } else {
        entry.target.classList.remove('section-start');
      }
    });
  }, {
    threshold: 0.5,
    root: null
  });

  const observerForOthers = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio >= 0.7) {
        entry.target.classList.add('section-start');
      } else {
        entry.target.classList.remove('section-start');
      }
    });
  }, {
    threshold: 0.7,
    root: null
  });

  const mq = window.matchMedia(`(max-width: ${BREAKPOINTS.tablet - 1}px)`);

  if (mq.matches) {
    const introSections = document.querySelectorAll('.section-intro-slider-box');
    introSections.forEach((element) => observerForIntro.observe(element));

    const otherSections = document.querySelectorAll('.section-trigger:not(.section-intro-slider-box)');
    otherSections.forEach((element) => observerForOthers.observe(element));
  } else {
    const sections = document.querySelectorAll('.section-trigger');
    sections.forEach((element) => observerForOthers.observe(element));
  }
};

export default animationTrigger;
