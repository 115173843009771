export const BREAKPOINTS = {
  min: 375,
  phone: 577,
  tabletSmall: 769,
  tablet: 1025,
  laptop: 1280,
  laptopLarge: 1441,
  desktop: 1600,
};

export const STORAGE_KEYS = {
  drawer: {
    quote: {
      status: "quote_drawer_status",
    },
    progress: {
      status: "progress_drawer_status",
    },
  },
};

export const ERROR_MESSAGE = {

};

export const SUCCESS_MESSAGE = {
  formSent: "Your message was successfully sent",
};
