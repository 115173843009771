import { preventTransition } from "@shared/preventTransition";
import scrollbarShiftFix from "@shared/scrollbarShiftFix";
import langSwitcher from "@shared/langSwitcher";
import { sectionAppearing } from "@shared/animations";
import { mobileMenu } from "@ui/Header";
import tooltip from "@ui/Tooltip";
import { dropdownMobile } from "@ui/Dropdown";
import { animationTrigger } from "@shared/animations";

document.addEventListener("DOMContentLoaded", () => {
  const root = document.querySelector(':root');
  const windowHeight = window.screen.height;

  root.style.setProperty('--window-height', `${windowHeight}px`);

  document.body.classList.remove("preload");

  window.addEventListener("scroll", () => {
    const header = document.querySelector(".header");
    const isScrolled = window.scrollY !== 0;

    header.classList.toggle("header--scrolled", isScrolled);
  });

  if(localStorage.getItem('utm_cook3') != null) {
    document.querySelectorAll('a').forEach(link => {
      if(link.getAttribute('href').includes('tabletki')) {
        link.setAttribute('href', link.getAttribute('href') + localStorage.getItem('utm_cook3'));
      }
    });
  }

  preventTransition();
  scrollbarShiftFix();
  sectionAppearing();
  animationTrigger();
  mobileMenu();
  langSwitcher();
  tooltip();
  dropdownMobile();
});
