const dropdownMobile = () => {
  const breakpoint = window.matchMedia(`(max-width: 1330px)`);

  const init = () => {
    const elements = {
      dropdownParents: document.querySelectorAll(".header-dropdown"),
    };
    const dropdownToggle = (element) => {
      element.classList.toggle("header-dropdown--active");
    };

    elements.dropdownParents.forEach((dropdownParent) => {
      dropdownParent.addEventListener("click", (event) => {
        dropdownToggle(event.currentTarget);
      });
    });
  };

  const handleBreakpointChange = (event) => {
    if (event.matches) {
      init();
    }
  };

  if (breakpoint.matches) {
    init();
  }

  breakpoint.addEventListener("change", handleBreakpointChange);
};

export default dropdownMobile;
